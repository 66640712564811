@import '../../../assets/scss/color';

#Header {
    .configs{
        .title{
            span{
                text-align: center;
            }
        }
    }
    .upload-file-visible {
        display: flex !important;
    }
    
    .upload-file-hidden {
        display: none !important;
    }
}