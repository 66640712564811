@import "../../assets/scss/color";

.mainHome {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title{
        position: absolute;
        font-size: 50px;
        font-weight: 600;
        font-family: "slab";
        top: 80px;
        span{
            font-size: 55px;
        }

    }
    .bc {
        span{
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 15px;
        }
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .boxs{
            flex-direction: row; 
        }
        .box{
            margin: 0 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }

    button {
        background-color: $bgIten;
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: .6);
        border: none;
        border-radius: 10px;
        width: 250px;
        height: 80px;
        color: $span;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        margin: 15px 0;

        &:hover {
            opacity: .8;
        }
    }

    button:disabled{
        opacity: .2;
        cursor: default;
    }
    .credts{
        position: absolute;
        bottom: 10px;
        a{
            color: #9998c2e5;
            text-decoration: underline;
        }
        span{
            font-size: 15px;
            opacity: .6;
        }
    }
}