// Definindo as cores principais
$primary-blue: #007bff; // Azul principal
$primary-yellow: #ffc107; // Amarelo principal
$primary-red: #dc3545; // Vermelho principal
$primary-green: #28a745; // Verde principal


// Variações de azul
$blue-light: #66b2ff; // Azul claro
$blue-light2: #66FAFF;
$blue-dark: #0056b3; // Azul escuro
$blue-darker: #003d80; // Azul ainda mais escuro

// Variações de amarelo
$yellow-light: #FFEFBD; // Amarelo claro
$yellow-dark: #ffca2c; // Amarelo escuro
$yellow-darker: #e0a800; // Amarelo ainda mais escuro

// Variações de vermelho
$red-light: #FC7B78; // Vermelho claro
$red-dark: #e02d2d; // Vermelho escuro
$red-darker: #c82333; // Vermelho ainda mais escuro

// Variações de verde
$green-light: #b9fdc9; // Verde claro
$green-dark: #1e7e34; // Verde escuro
$green-darker: #155724; // Verde ainda mais escuro

// Variaçoes boxs
$green-half: #00A513;
$clear-purple: #940FC0;
$shit-red: #ED6060;
$light-blue: #009DC0;
$bar-color: #00CAC0;
$iten-yellow: #B8CD18;
$circle: #4995EB;

// Variações de preto
$black-light: #343a40; // Preto claro
$black-dark: #212529; // Preto escuro
$black-darker: #000000; // Preto ainda mais escuro

// Variações de branco
$white-light: #FAFAFA; // Branco claro
$white-dark: #e9ecef; // Branco escuro
$white-darker: #ffffff; // Branco ainda mais escuro

// Cores de fundo e outros elementos
$background-color: #ffffff; // Cor de fundo principal
$foreground-color: #f8f9fa; // Cor de fundo secundária
