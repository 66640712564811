@import '../../../assets/scss/color';

#MainActivities {
    padding: 20px;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;

    .message {
        position: relative;

        .copy {
            cursor: default;
            background-color: $bgColorTec;
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            justify-content: center;
            align-items: center;

            span {
                font-size: 30px;
                font-weight: 500;
            }
        }

        div {
            cursor: pointer;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        width: 70%;
        height: auto;
        justify-content: center;
        align-items: center;
        background-color: $bgColorTec;
        box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
        border-radius: 10px;
        padding: 30px;

        pre {
            font-size: 16px;
            color: $span;
            font-size: 500;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .bgTable {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .buttonDn {
            position: relative;
            width: 100%;
            padding: 10px;
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                width: 150px;
                border: none;
                background-color: $corverde;
                padding: 10px 20px;
                color: white;
                font-size: 18px;
                font-weight: 600;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                    opacity: .8;
                }

                &:disabled {
                    cursor: default;
                    background-color: rgb(32, 32, 32);

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .table {
            width: 80%;
            height: auto;
            position: relative;
            flex-direction: column;

            span {
                color: #000000;
                white-space: nowrap;
            }

            .headerTable {
                background-color: #fff;
                box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
                width: 100%;
                height: 80px;
                flex-direction: row;
                z-index: 10;

                .img {
                    width: 200px;
                    height: 80px;
                    background-image: url("../../../assets/img/logoTotal.png");
                    background-size: 200px auto;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .title {
                    width: calc(100% - 400px);
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    span:nth-child(1) {
                        font-size: 30px;
                        font-weight: 500;
                        color: rgba($color: #000000, $alpha: 100);
                    }

                    span:nth-child(2) {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba($color: #000000, $alpha: .80);
                    }
                }

                .logo {
                    position: relative;
                    width: 200px;

                    .bgLogo {
                        position: absolute;
                        width: 100px;
                        height: 100px;
                        right: 20px;
                        top: -5px;
                        background-image: url("../../../assets/img/ASS_Black.png");
                        background-size: 100px auto;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }
            }

            .mainTable {
                padding-top: 20px;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                background-color: #fff;

                .data {
                    span {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }

                .itemTable {
                    position: relative;
                    border-radius: 18px;
                    border: solid 1px rgba($color: #000000, $alpha: .10);
                    margin-bottom: 15px;
                    box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
                    background-color: #fff;
                    margin-top: 10px;
                    width: 85%;
                    height: 70px;
                    align-items: center;

                    .position {
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 100%;
                        border-top-left-radius: 18px;
                        border-bottom-left-radius: 18px;
                        background-color: #6332F3;

                        span {
                            font-size: 22px;
                            font-weight: 500;
                            color: #fff;
                        }
                    }

                    .nameAndRouter {
                        width: 300px;
                        height: 100%;
                        justify-content: center;
                        flex-direction: column;
                        padding-left: 15px;

                        .name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: #150557;
                            font-size: 20px;
                            font-weight: 600;
                        }

                        .router {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-top: 5px;
                            color: #1353F5;
                            font-size: 12px;
                        }
                    }

                    .type {
                        width: 40px;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .values {
                        width: calc(100% - 470px);
                        height: 100%;
                        justify-content: space-evenly;
                        align-items: center;

                        div {
                            width: 40%;
                            max-width: 130px;
                            border-radius: 20px;
                            border: solid 1px rgba($color: #000000, $alpha: .10);
                            box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
                            height: 50px;
                            margin: 0 5px;
                            justify-content: space-evenly;
                            align-items: center;

                            svg {
                                width: 22px;
                                height: 22px;
                                margin-right: 5px;
                            }

                            span {
                                width: 30px;
                                font-size: 18px;
                                font-weight: 500;
                            }
                        }
                    }

                    .percentage {
                        width: 80px;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}