@import '../../../assets/scss/color';

#MainPP {
    overflow-y: auto;

    .edit {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .custom-select {
            position: relative;
            width: 400px;

            .dropdown {
                text-align: center;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                max-height: 600px;
                overflow-y: auto;
                background: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                z-index: 1000;
                list-style: none;
                margin: 0;
                padding: 0;

                .dropdown-item {
                    padding: 8px;
                    cursor: pointer;
                }

                .dropdown-item:hover {
                    background: #c9c9c9;
                }

                .custom-input {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    outline: none;
                }
            }

            input {
                width: 100%;
                height: 30px;
                border: none;
                border-radius: 2px;
                margin: 10px 0 5px;
                text-align: center;
            }
        }

        .button {
            width: auto;
            height: min-content;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            button {
                width: 150px;
                height: 35px;
                border: none;
                background-color: $button;
                padding: 10px 20px;
                color: white;
                font-size: 16px;
                font-weight: 600;
                border-radius: 5px;
                margin: 20px 10px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }
            }

            button:nth-child(2) {
                background-color: #bb2929;
            }

            button:disabled {
                cursor: default;
                background-color: rgba(32, 32, 32);
                opacity: .4;

                &:hover {
                    opacity: .4;
                }
            }
        }
    }

    .editBox {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 10;

        .itemEdit {
            width: 600px;
            height: 500px;
            background-color: $bgColorLight;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            .icon {
                position: absolute;
                right: -10px;
                top: -10px;
                width: 25px;
                height: 25px;
                cursor: pointer;

                svg {
                    width: 100%;
                    height: 100%;
                    color: $alert;
                }
            }

            .title {
                justify-content: center;
                align-items: center;
                height: 50px;
                span {
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }           


            .cafList {
                flex-direction: column;
                align-items: center;
                width: 80%;
                height: 320px;
                padding: 10px 0;
                overflow-y: auto;
                background-color: rgba($color: #000000, $alpha: .1);
                border-radius: 10px;

                .cafItem {
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 10px;
                    border-bottom: solid 1px rgba($color: #FFFFFF, $alpha: .2);
                    justify-content: center;
                    align-items: center;
                    .cafInfo{
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        span{
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 5px;
                        }
                        
                    }
                    .dataLoading {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        select {
                            width: 200px;
                            height: 25px;
                            text-align: center;
                            color: rgba($color: $span, $alpha: .8);
                            border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
                            background-color: rgba($color: #000000, $alpha: 0);
                            border-radius: 10px;
                            height: 40px;
                            appearance: none;

                            option {
                                color: black;
                            }
                        }
                    }

                    .observation {
                        align-items: center;
                        position: relative;
                        height: 60px;
                        width: 200px;

                        input {
                            text-align: center;
                            color: rgba($color: $span, $alpha: .8);
                            width: 200px;
                            border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
                            background-color: rgba($color: #000000, $alpha: 0);
                            border-radius: 2px;
                            height: 30px;
                        }
                    }
                }
                .cafItem:last-of-type{
                    border: none;
                }

            }

            .buttonDn {
                position: relative;
                width: 100%;
                padding: 10px;
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                button {
                    width: 150px;
                    border: none;
                    background-color: $corverde;
                    padding: 10px 20px;
                    color: white;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 5px;
                    cursor: pointer;
                    margin-top: 20px;

                    &:hover {
                        opacity: .8;
                    }

                    &:disabled {
                        cursor: default;
                        background-color: rgb(32, 32, 32);

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .editBox-false {
        display: none;
    }

    .message {
        position: relative;

        .copy {
            cursor: default;
            background-color: $bgColorTec;
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            justify-content: center;
            align-items: center;

            span {
                font-size: 30px;
                font-weight: 500;
            }
        }

        div {
            cursor: pointer;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        width: 70%;
        height: auto;
        justify-content: center;
        align-items: center;
        background-color: $bgColorTec;
        box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
        border-radius: 10px;
        padding: 30px;

        pre {
            font-size: 16px;
            color: $span;
            font-size: 500;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .graphic {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;

        .sheet {
            background-color: rgb(255, 255, 255);
            width: 80%;
            height: auto;
            flex-direction: column;
            border-radius: 5px;

            span {
                color: #000000;
            }

            .herderSheet {
                width: 100%;
                height: 100px;
                position: relative;

                div {
                    justify-content: center;
                    align-items: center;
                }

                .logo {
                    width: 30%;
                    justify-content: flex-start;
                    padding: 10px;

                    .bgImage {
                        width: 200px;
                        height: 80px;
                        background-image: url("../../../assets/img/logoTotal.png");
                        background-size: 200px auto;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                .title {
                    width: 40%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    span:nth-child(1) {
                        font-size: 35px;
                        font-weight: 600;
                        color: #1E2348;
                    }

                    span:nth-child(2) {
                        font-size: 35px;
                        font-weight: 600;
                        color: #a80000;

                    }

                }

                .logoProperty {
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    right: 20px;
                    bottom: 0px;
                    background-image: url("../../../assets/img/ASS_Black.png");
                    background-size: 100px auto;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .mainSheet {
                width: 100%;
                height: auto;
                background-color: #ffffff;
                flex-direction: row;

                .parts {
                    padding: 10px;
                    width: 50%;
                    height: auto;
                    flex-direction: column;

                    .item {
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        margin-bottom: 15px;

                        .headerItem {
                            flex-direction: row;
                            justify-content: space-around;
                            width: 100%;
                            height: 45px;
                            background-color: #FFFFFF;
                            border-radius: 5px;
                            border: solid .1px rgba($color: #000000, $alpha: .1);
                            box-shadow: 0 -2px 4px 0 rgba($color: #000000, $alpha: .25);

                            .partItem {
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                span {
                                    font-size: 15px;
                                    font-weight: 500;
                                }
                            }

                            .paartRouter {
                                justify-content: center;
                                align-items: flex-end;

                                span {
                                    font-weight: 700;
                                    margin-bottom: 5px;
                                    font-size: 22px;
                                }
                            }
                        }

                        .mainItem {
                            width: 95%;
                            flex-direction: column;
                            background-color: #F6F6F6;
                            border: solid 1px rgba($color: #000000, $alpha: .1);
                            box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                            padding-bottom: 5px;

                            .titles {
                                display: grid;
                                grid-template-columns: 30% 20% 20% 30%;
                                background-color: #D9D9D9;
                                margin-bottom: 5px;

                                div {
                                    justify-content: center;
                                    align-items: center;

                                    span {
                                        font-size: 14px;
                                        font-weight: 500;
                                    }
                                }
                            }

                            .caf {
                                cursor: pointer;
                                flex-direction: column;
                                .itens{
                                    display: grid;
                                    grid-template-columns: 30% 20% 20% 30%;
                                    div {
                                        width: 100%;
                                        justify-content: center;
                                        align-items: center;
    
                                        span {
                                            font-size: 14px;
                                            font-weight: 400;
                                        }
                                    }
    
                                    div:nth-child(4) {
                                        align-items: center;
                                        justify-content: center;
    
                                        .obs {
                                            display: flex;
                                            width: 90%;
                                            border-bottom: solid 1px rgba($color: #000000, $alpha: .2);
                                        }
                                    }
                                }
                                .awbs{
                                    justify-content: center;
                                    align-items: center;
                                    width: 100%;
                                    margin-bottom: 5px;
                                    background-color: rgba($color: #000000, $alpha: .08);
                                    .awbList{
                                        padding: 2px 0;
                                        justify-content: center;
                                        flex-direction: column;
                                        span{
                                            font-size: 12px;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .footerSheet{
                width: 100%;
                height: 60px;
                box-shadow: 0 -4px 10px 2px rgba($color: #000000, $alpha: .08);
                justify-content: space-around;
                align-items: center;
                div{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 25%;
                    height: 100%;
                    border-left: solid 1px rgba($color: #000000, $alpha: .25);
                    border-right: solid 1px rgba($color: #000000, $alpha: .25);
                    span{
                        margin: 2px;
                    }
                    span:nth-child(1){
                        font-size: 18px;
                        font-weight: 600;
                    }
                    span:nth-child(2){
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }

        .buttonDn {
            position: relative;
            width: 100%;
            padding: 10px;
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                width: 150px;
                border: none;
                background-color: $corverde;
                padding: 10px 20px;
                color: white;
                font-size: 18px;
                font-weight: 600;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                    opacity: .8;
                }

                &:disabled {
                    cursor: default;
                    background-color: rgb(32, 32, 32);

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}