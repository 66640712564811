.calendarBox {
    width: 300px;
    height: 95%;
    justify-content: center;
    align-items: center;

    .react-calendar {
        padding: 10px;
        overflow: hidden;
        width: 100%;
        height: min-content;
        border: none;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        font-family: 'Arial', sans-serif;
        flex-direction: column;

        .react-calendar__navigation {
            width: 100%;
            height: 25px;
            justify-content: center;
            align-items: center;

            button {
                border: none;
                background-color: rgba(0, 0, 0, 0);
            }

            .react-calendar__navigation__arrow {
                height: 100%;
                margin: 0 2px;
                width: min-content;
                justify-content: center;
                align-items: center;
            }

            .react-calendar__navigation__label {
                pointer-events: none;
                height: 100%;
                width: 150px;
                justify-content: center;
                align-items: center;

                span {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }

        .react-calendar__viewContainer {
            width: 100%;
            height: calc(100% - 25px);

            .react-calendar__month-view {
                width: 100%;
                justify-content: center;
                align-items: center;

                div:nth-child(1) {
                    height: 100%;

                    div:nth-child(1) {
                        height: 100%;
                        flex-direction: column;

                        .react-calendar__month-view__weekdays {
                            height: 25px;
                            display: grid;
                            grid-template-columns: repeat(7, 1fr);

                            div {
                                height: 100%;
                                justify-content: center;
                                align-items: center;

                                abbr {
                                    font-weight: 600;
                                }
                            }
                        }

                        .react-calendar__month-view__days {
                            padding: 10px;
                            height: auto;
                            display: grid;
                            grid-template-columns: repeat(7, 1fr);
                            grid-gap: 0 15px;
                            justify-content: center;
                            align-items: center;

                            button {
                                margin: 0;
                                cursor: pointer;
                                padding: .25em;
                                background-color: rgba(0, 0, 0, 0);
                                border: none;
                                border-radius: 50%;
                                justify-content: center;
                                align-items: center;
                                align-self: center;
                                text-align: center;

                                &:hover {
                                    background-color: rgba(0, 61, 128, .8);

                                    abbr {
                                        color: rgba(255, 255, 255, .7);
                                    }
                                }

                                abbr {
                                    color: rgba(0, 0, 0, .7);
                                    font-weight: 500;
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .calendar__otherMonth {
        abbr {
            opacity: 0.5;
        }
    }

    .selectedDay {
        background-color: rgb(144, 32, 189) !important;

        abbr {
            color: white !important;
        }
    }

    .daytime {
        background-color: rgba(17, 0, 255, .5) !important;

        abbr {
            color: white !important;
        }
    }

    .daytime + .calendar__otherMonth, .calendar__otherMonth + .daytime{
        background-color: rgba(17, 0, 255, .2) !important;

        abbr {
            color: rgba(255, 255, 255, .8) !important;
        }
    }


}