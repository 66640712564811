@import '../../../assets/scss/color';

#PriorityParts{
    flex-direction: row;
    width: 100%;
    height: 100%;
    #Header{
        width: 18rem;
        height: 100%;
        background-color: $bgColorSec;
        flex-direction: column;
        box-shadow: 2px 0 4px 0 rgba($color: #000000, $alpha: .2);
        align-items: center;
        padding: 0 10px;
    }
    #MainPP{
        width: calc(100% - 18rem);
        height: 100%;  
        padding: 10px;
        flex-direction: column;
        align-items: center;
    }
}