// Paleta de Cores Baseadas nas Cores Originais

$bgColor: #111525;
$bgColorSec: #161B2E;
$bgColorTec: #161C31;
$span: #FFFFFF;

$bgIten: #1E61DC;
$alert: #F44336;
$button: #0EA440;

// Cores Primárias
$primary: #1E61DC;           // Azul vibrante (usado como cor principal)
$primary-light: #5393FF;     // Azul claro
$primary-dark: #144BB5;      // Azul escuro

// Cores Secundárias
$secondary: #0EA440;         // Verde vibrante (usado como cor secundária)
$secondary-light: #4CE17A;   // Verde claro
$secondary-dark: #0A7830;    // Verde escuro

// Cores de Destaque
$alert: #F44336;             // Vermelho para alertas
$warning: #FFC107;           // Amarelo para avisos
$success: #4CAF50;           // Verde para sucesso

// Cores Neutras
$gray-light: #E0E0E0;        // Cinza claro
$gray-medium: #9E9E9E;       // Cinza médio
$gray-dark: #424242;         // Cinza escuro

// Cores Complementares
$complementary: #673AB7;     // Roxo vibrante
$complementary-light: #9575CD; // Roxo claro
$complementary-dark: #512DA8;  // Roxo escuro
$complementary-dark2: #760F9E; // Roxo mais escuro

// Cores de destaque sobre o fundo $complementary-dark
$highlight-item: #FFC107;  // Amarelo para destacar itens (brilho e atenção)    
$highlight-item-White: #c09618;   
$highlight-warning: #26ffd0;  
$highlight-warning-White: #00c499;     // Laranja avermelhado para aviso (alerta visual)
$highlight-total: #76FF03;       // Verde limão para total (sucesso e resultado positivo)


// Usos Específicos de Cores
$bgColorLight: #1A2135;      // Um tom mais claro do fundo principal
$bgColorSecLight: #20263C;   // Um tom mais claro do fundo secundário
$bgColorDark: #0C101D;       // Um tom mais escuro do fundo principal
$bgColorTecDark: #0F1424;    // Um tom mais escuro do fundo técnico
$dark: #000000;

// Variantes de Alertas
$alertLight: #FF7961;        // Vermelho claro para alertas mais suaves
$alertDark: #B71C1C;         // Vermelho escuro para alertas severos

// Variantes de Botões
$buttonHover: #0B8A38;       // Verde escuro para hover em botões
$buttonDisabled: #74C692;    // Verde desabilitado para botões inativos

$dark1: #36393f;
$dark2: #2f3136;
$dark3: #292b2f;

$corbutton: #242fad;
$corbuttonoff: #1f288f;
$corspan: #d6d7d9;

$corverde: #28a745;
$corvermelho: #FF2111;
$coramarelo: #FBCE3B;

$white1: #DEE7FD;
$white2: #D7E0F5;
$white3: #C9D9FF;

$Online: #3ba55c;
$Offline: #FF2111;
$Unavailable: #FBCE3B;
