@import '../../../assets/scss/color';

#Main {
    overflow-y: auto;

    .edit {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .custom-select {
            position: relative;
            width: 400px;

            .dropdown {
                text-align: center;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                max-height: 600px;
                overflow-y: auto;
                background: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                z-index: 1000;
                list-style: none;
                margin: 0;
                padding: 0;

                .dropdown-item {
                    padding: 8px;
                    cursor: pointer;
                }

                .dropdown-item:hover {
                    background: #c9c9c9;
                }

                .custom-input {
                    width: 100%;
                    padding: 8px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    outline: none;
                }
            }

            input {
                width: 100%;
                height: 30px;
                border: none;
                border-radius: 2px;
                margin: 10px 0 5px;
                text-align: center;
            }
        }

        .button {
            width: auto;
            height: min-content;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            button {
                width: 150px;
                height: 35px;
                border: none;
                background-color: $button;
                padding: 10px 20px;
                color: white;
                font-size: 16px;
                font-weight: 600;
                border-radius: 5px;
                margin: 20px 10px;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }
            }

            button:nth-child(2) {
                background-color: #bb2929;
            }
            button:disabled {
                cursor: default;
                background-color: rgba(32, 32, 32);
                opacity: .4;

                &:hover {
                    opacity: .4;
                }
            }
        }
    }
    .editBox{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 10;
        .itemEdit{
            width: 400px;
            height: 350px;
            background-color: $bgColorLight;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            .icon{
                position: absolute;
                right: -10px;
                top: -10px;
                width: 25px;
                height: 25px;
                cursor: pointer;
                svg{
                    width: 100%;
                    height: 100%;
                    color: $alert;
                }
            }
            .nameCAF{
                align-items: center;
                position: relative;
                height: 100px;
                width: 300px;
                svg {
                    position: absolute;
                    left: 10px;
                    width: 22px;
                    height: 22px;
                    color: rgba($color: $span, $alpha: .6)
                }
                input {
                    padding-left: 40px;
                    color: rgba($color: $span, $alpha: .8);
                    width: 300px;
                    border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
                    background-color: rgba($color: #000000, $alpha: 0);
                    border-radius: 10px;
                    height: 40px;
                }
            }
            .title{
                justify-content: center;
                align-items: center;
                height: 50px;
                span{
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }
            .dataLoading{
                flex-direction: column;
                justify-content: center;
                align-items: center;
                select{
                    width: 200px;
                    height: 25px;
                    text-align: center;
                    color: rgba($color: $span, $alpha: .8);
                    border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
                    background-color: rgba($color: #000000, $alpha: 0);
                    border-radius: 10px;
                    height: 40px;
                    appearance: none;
                    option{
                        color: black;
                    }
                }
            }
            .buttonDn {
                position: relative;
                width: 100%;
                padding: 10px;
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: center;
    
                button {
                    width: 150px;
                    border: none;
                    background-color: $corverde;
                    padding: 10px 20px;
                    color: white;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 5px;
                    cursor: pointer;
                    margin-top: 20px;
    
                    &:hover {
                        opacity: .8;
                    }
    
                    &:disabled {
                        cursor: default;
                        background-color: rgb(32, 32, 32);
    
                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .editBox-false{
        display: none;
    }

    .message {
        position: relative;

        .copy {
            cursor: default;
            background-color: $bgColorTec;
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            justify-content: center;
            align-items: center;

            span {
                font-size: 30px;
                font-weight: 500;
            }
        }

        div {
            cursor: pointer;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        width: 70%;
        height: auto;
        justify-content: center;
        align-items: center;
        background-color: $bgColorTec;
        box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
        border-radius: 10px;
        padding: 30px;

        pre {
            font-size: 16px;
            color: $span;
            font-size: 500;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .graphic {
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;

        .sheet {
            background-color: rgb(255, 255, 255);
            width: 80%;
            height: auto;
            flex-direction: column;
            border-radius: 5px;

            .herderSheet {
                width: 100%;
                height: 130px;
                position: relative;

                div {
                    justify-content: center;
                    align-items: center;
                }

                .logo {
                    width: 30%;
                    justify-content: flex-start;
                    padding: 10px;

                    .bgImage {
                        width: 200px;
                        height: 130px;
                        background-image: url("../../../assets/img/logoTotal.png");
                        background-size: 200px auto;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                }

                .title {
                    width: 40%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    span:nth-child(1) {
                        font-size: 25px;
                        font-weight: 600;
                        color: #1E2348;
                    }

                    span:nth-child(2) {
                        font-size: 20px;
                        font-weight: 600;
                        color: #a80000;

                    }

                }

                .date {
                    width: 30%;
                    padding: 10px;
                    justify-content: flex-end;
                    align-items: flex-start;

                    span {
                        font-size: 20px;
                        color: rgba(0, 0, 0, .8);
                        font-weight: 600;
                    }
                }

                .logoProperty {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    right: 5px;
                    bottom: 0px;
                    background-image: url("../../../assets/img/ASS_Black.png");
                    background-size: 50px auto;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .mainSheet {
                width: 100%;
                height: auto;
                background-color: rgb(255, 255, 255);
                flex-direction: column;
                border-right: solid 1px rgba(255, 255, 255, 0.8);
                border-left: solid 1px rgba(255, 255, 255, 0.8);

                .headerMainSheet {
                    height: 30px;
                    width: 100%;
                    background-color: #1E2348;

                    div {
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        border-left: solid 1px rgba(255, 255, 255, .2);
                        border-right: solid 1px rgba(255, 255, 255, .2);
                        
                        span {
                            font-weight: 600;
                        }
                    }

                    .bcRouter {
                        
                        width: 10%;
                        height: 100%;
                    }

                    .bcAggregate {
                        width: 30%;
                        height: 100%;
                    }

                    .bcCAF {
                        width: 10%;
                        height: 100%;
                    }

                    .bcAmount {
                        width: 10%;
                        height: 100%;
                    }

                    .bcState {
                        width: 20%;
                        height: 100%;

                    }

                    .bcSituation {
                        width: 20%;
                        height: 100%;
                    }

                }

                .added {
                    height: auto;
                    width: 100%;
                    background-color: #ffffff;

                    div {
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        border-left: solid 1px rgba(0, 0, 0, 0.2);
                        border-right: solid 1px rgba(0, 0, 0, 0.2);

                        span {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            align-items: center;
                            font-size: 13px;
                            color: black;
                            font-weight: 600;
                            user-select: text;
                            white-space: nowrap;
                            /* Impede a quebra de linha */
                            overflow: hidden;
                            /* Opcional: Oculta o texto que ultrapassar os limites */
                            text-overflow: ellipsis;

                            /* Opcional: Adiciona "..." se o texto for cortado */
                            svg {
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;

                            }

                            .red {
                                color: red;
                            }

                            .yellow {
                                color: rgb(199, 202, 0);
                            }
                        }
                    }

                    .bcRouter {
                        width: 10%;
                        height: 100%;
                        .itenSelected{
                            display: none;
                        }
                        span {
                            font-size: 18px;
                        }
                    }

                    .bcAggregate {
                        width: 30%;
                        height: 100%;

                        span {
                            font-size: 13px;
                        }
                    }

                    .bcCAF {
                        width: 10%;
                        height: 100%;

                        span {
                            font-size: 14px;
                        }
                    }

                    .bcAmount {
                        width: 10%;
                        height: 100%;

                        span {
                            font-size: 18px;
                        }
                    }

                    .bcState {
                        width: 20%;
                        height: 100%;

                        span {
                            font-size: 16px;
                        }

                    }

                    .bcSituation {
                        width: 20%;
                        height: 100%;

                        span {
                            font-size: 16px;
                        }
                    }
                }
                
                .addedBG {
                    background-color: #dfdfdf;
                }
                .selected{
                    .itenSelected{
                        display: flex !important;
                    }
                }

                .dc-inCAF {
                    div {

                        span {
                            color: rgb(38, 65, 153);
                        }
                    }
                }

                .dc-justLeft {
                    div {
                        span {
                            color: rgb(4, 148, 4);
                        }
                    }
                }

                .dc-inStreet {
                    div {
                        span {
                            color: rgb(0, 97, 136);
                        }
                    }
                }
            }

            .footerSheet {
                width: 100%;
                background-color: #1E2348;
                height: 50px;
                min-height: 50px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom: solid 1px rgba(255, 255, 255, 0.8);

                div {
                    height: 100%;
                    width: 100%;
                    background-color: #1E2348;
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border-right: solid 1px rgba(255, 255, 255, 0.8);
                    border-left: solid 1px rgba(255, 255, 255, 0.8);
                    width: 25%;

                    span {
                        font-weight: 600;
                    }
                }

                .volumefooter {}

                .Madefooter {}

                .auditedfooter {}

                .issuedfooter {}
            }
        }

        .buttonDn {
            position: relative;
            width: 100%;
            padding: 10px;
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                width: 150px;
                border: none;
                background-color: $corverde;
                padding: 10px 20px;
                color: white;
                font-size: 18px;
                font-weight: 600;
                border-radius: 5px;
                cursor: pointer;
                margin-top: 20px;

                &:hover {
                    opacity: .8;
                }

                &:disabled {
                    cursor: default;
                    background-color: rgb(32, 32, 32);

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}