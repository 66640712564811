@import '../../../assets/scss/color';

.headerReport {
    width: 300px;
    height: 100%;
    background-color: $bgColorSec;
    flex-direction: column;
    box-shadow: 2px 0 4px 0 rgba($color: #000000, $alpha: .2);

    .title {
        width: 100%;
        height: 80px;
        justify-content: center;
        align-items: center;

        span {
            font-size: 30px;
            font-weight: 600;
            color: $span;
        }
    }

    .name {
        width: 100%;
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text {
            margin-bottom: 10px;

            span {
                font-size: 25px;
                font-weight: 500;
                color: rgba($color: $span, $alpha: .8);
            }
        }

        .input {
            width: min-content;
            align-items: center;
            position: relative;

            svg {
                position: absolute;
                left: 10px;
                width: 22px;
                height: 22px;
                color: rgba($color: $span, $alpha: .6)
            }

            input {
                padding-left: 40px;
                color: rgba($color: $span, $alpha: .8);
                width: 250px;
            }
        }

    }

    .date {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100px;

        .text {
            margin-bottom: 10px;

            span {
                font-size: 16px;
                font-weight: 500;
                color: rgba($color: $span, $alpha: .6);
            }
        }

        .input {
            width: min-content;
            align-items: center;
            position: relative;

            svg {
                position: absolute;
                left: 10px;
                width: 22px;
                height: 22px;
                color: rgba($color: $span, $alpha: .6)
            }

            input {
                padding-left: 40px;
                color: rgba($color: $span, $alpha: .8);
                width: 200px;
            }
        }

    }

    .select {
        width: 100%;
        height: 70px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span {
            font-weight: 500;
            margin: 0 10px;
            opacity: .8;
            margin-bottom: 5px;
        }
    }
    .inffoSelect{
        width: 100%;
        height: 40px;
        justify-content: center;
        align-items: center;
        span{
            font-size: 10px;
            opacity: .6;
        }
    }

    .upload {
        width: 100%;
        height: calc(100% - 310px);
        overflow-y: auto;
        flex-direction: column;

        .itenUpload {
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            .dropzone {
                justify-content: center;
                align-items: center;
                border: dotted 1px rgba($color: $span, $alpha: .8);
                border-radius: 20px;
                width: 160px;
                height: 160px;

                svg {
                    width: 60px;
                    height: 60px;
                    color: #888888;
                }
            }

            span {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 10px;
                color: rgba($color: $span, $alpha: .6);
            }
        }
    }

    input {
        border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
        background-color: rgba($color: #000000, $alpha: 0);
        border-radius: 10px;
        height: 40px;
    }
}

.mainReport {
    width: calc(100% - 300px);
    height: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    overflow-y: auto;

    .message {
        position: relative;

        .copy {
            cursor: default;
            background-color: $bgColorTec;
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            justify-content: center;
            align-items: center;

            span {
                font-size: 30px;
                font-weight: 500;
            }
        }

        div {
            cursor: pointer;
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        width: 70%;
        height: auto;
        justify-content: center;
        align-items: center;
        background-color: $bgColorTec;
        box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
        border-radius: 10px;
        padding: 30px;

        pre {
            font-size: 16px;
            color: $span;
            font-size: 500;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    .table {
        margin-top: 50px;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .button {

            button {
                border: none;
                width: 50px;
                height: 40px;
                border-radius: 10px;
                background-color: $button;
                box-shadow: 0 2px 4px 0 rgba($color: $span, $alpha: .25);
                margin: 20px 10px;
                cursor: pointer;

                svg {
                    width: 20px;
                    height: 20px;
                    color: $span;
                }

                &:nth-child(2) {
                    background-color: $primary;
                }
            }
        }

        .bgTable {
            width: 80%;
            height: auto;
            background-color: $bgColorTec;
            box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
            border-radius: 10px;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            img {
                position: absolute;
                width: 110px;
                height: 90px;
                left: 40px;
                top: 20px;
            }

            .title {
                position: relative;
                width: 100%;
                height: 80px;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .suari {
                    flex-direction: column;
                    text-align: center;

                    span:nth-child(1) {
                        font-size: 30px;
                        font-weight: 600;
                    }

                    span:nth-child(2) {
                        padding: 5px 0;
                        font-size: 16px;
                        opacity: .6;

                    }
                }

                .secInffo {
                    position: absolute;
                    right: 0px;
                    top: 0;

                    span {
                        font-size: 15px;
                        font-weight: 600;
                        opacity: .8;
                    }
                }
            }

            .itensTable {
                margin-top: 40px;
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .headerTable {
                    width: 100%;
                    height: 40px;
                    margin-bottom: 10px;
                    justify-content: center;
                    align-items: center;

                    span {
                        width: 25%;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: 600;
                        justify-content: center;
                        text-align: center;
                    }
                }

                .iten {
                    overflow: hidden;
                    background-color: $complementary-dark;
                    box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: .25);
                    width: 100%;
                    height: 60px;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;

                    .routerIten {
                        width: 25%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .priorityIten {
                        width: 25%;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        div {
                            width: auto;
                            height: 25px;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            svg {
                                margin: 0 2px;
                                width: 25px;
                                height: 25px;
                                color: $alert;
                            }
                        }

                        div:nth-child(1) span {
                            font-size: 20px;
                            font-weight: 500;
                        }

                        div:nth-child(2) span {
                            margin: 0 2px;
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .valueIten {
                        width: 25%;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        svg {
                            margin-right: 8px;
                            width: 30px;
                            height: 30px;
                            color: rgba($color: $span, $alpha: .8);
                        }

                        span {
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .itenEXP {

                        svg,
                        span {
                            color: $highlight-item;
                        }
                    }

                    .itenSTD {

                        svg,
                        span {
                            color: $highlight-warning;
                        }
                    }

                    .itenTOTAL {

                        svg,
                        span {}

                    }
                }
            }
        }

        .bgTableWhite {
            width: 80%;
            height: auto;
            background-color: $span;
            box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
            border-radius: 10px;
            padding: 20px 30px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            img {
                position: absolute;
                width: 110px;
                height: 90px;
                left: 40px;
                top: 20px;
            }

            span {
                color: $dark !important;
            }

            .title {
                position: relative;
                width: 100%;
                height: 80px;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .suari {
                    flex-direction: column;
                    text-align: center;

                    span:nth-child(1) {
                        font-size: 30px;
                        font-weight: 600;
                    }

                    span:nth-child(2) {
                        padding: 5px 0;
                        font-size: 16px;
                        font-weight: 500;
                        opacity: .8;

                    }
                }

                .secInffo {
                    position: absolute;
                    right: 0px;
                    top: 0;

                    span {
                        font-size: 18px;
                        font-weight: 600;
                        opacity: .9;
                    }
                }
            }

            .itensTable {
                margin-top: 40px;
                width: 100%;
                height: auto;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .headerTable {
                    width: 100%;
                    height: 40px;
                    margin-bottom: 10px;
                    align-items: center;

                    span {
                        width: 25%;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: 600;
                        justify-content: center;
                        text-align: center;
                    }
                }

                .iten {
                    background-color: $span;
                    //box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: .25);
                    border: 1px solid rgba($color: $dark, $alpha: .4);
                    width: 100%;
                    height: 65px;
                    //margin-bottom: 10px;
                    border-radius: 5px;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;

                    .routerIten {
                        width: 25%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        span {
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .priorityIten {
                        width: 25%;
                        height: 100%;
                        padding: 5px 0;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        div {
                            width: auto;
                            height: 25px;
                            justify-content: center;
                            align-items: center;
                            text-align: center;

                            svg {
                                margin: 0 2px;
                                width: 25px;
                                height: 25px;
                                color: $alert;
                            }
                        }

                        div:nth-child(1) span {
                            font-size: 20px;
                            font-weight: 500;
                        }

                        div:nth-child(2) span {
                            margin: 0 2px;
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .valueIten {
                        width: 25%;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        svg {
                            margin-right: 8px;
                            width: 30px;
                            height: 30px;
                            color: rgba($color: $span, $alpha: .8);
                        }

                        span {
                            font-size: 25px;
                            font-weight: 600;
                        }
                    }

                    .itenEXP {

                        svg,
                        span {
                            color: $highlight-item-White;
                        }
                    }

                    .itenSTD {

                        svg,
                        span {
                            color: $highlight-warning-White;
                        }
                    }

                    .itenTOTAL {

                        svg,
                        span {}

                    }
                }
            }
        }
    }

    .gear {
        position: absolute;
        right: 10px;
        top: 10px;

        svg {
            cursor: pointer;
            width: 35px;
            height: 35px;
            color: $span;
        }
    }
}

.config {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: $bgColor;

    nav {
        width: 200px;
        border-right: solid 1px rgba($color: $span, $alpha: .05);
        padding: 5px;

        .option {

            width: 100%;
            height: 80px;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            background-color: $bgColorTec;
            box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: .25);
            border-radius: 10px;
            cursor: pointer;

            svg {
                margin-right: 10px;
                width: 30px;
                height: 30px;
                color: $span;
            }

            span {
                font-size: 20px;
                font-weight: 600;
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    .configOpen {
        width: calc(100% - 200px);

        .userConfig, .routerConfig{
            width: 100%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow-y: auto;
            div{
                width: auto;
                height: auto;
                padding: 10px 20px;
                margin-bottom: 20px;
                background-color: $complementary-dark;
                box-shadow: 0 2px 4px 0 rgba($color: #ffffff, $alpha: .25);
                border-radius: 10px;
                span{
                    font-size: 25px;
                    font-weight: 600;
                }
            }
        }
    }

    .gear {
        position: absolute;
        right: 10px;
        top: 10px;

        svg {
            cursor: pointer;
            width: 35px;
            height: 35px;
            color: $span;
        }
    }
}

.config-closed {
    display: none;
}

.config-open {
    display: flex;
}


@media (max-width: 768px) {
    .headerReport {
        display: none; // Esconde o cabeçalho por padrão
    }

    .mainReport {
        width: 100%;
        .table{
            .captureDiv{
                padding: 10px;
                img{
                    display: none;
                }
                .title{
                    padding-top: 25px;
                    .suari{
                        span:nth-child(1){

                        }
                        span:nth-child(2){
                            
                        }
                    }
                    .secInffo{
                        span{

                        }
                    }
                }
            }
        }
    }

}