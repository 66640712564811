@import '../../../assets/scss/color';

#Header {
    .configs {
        flex-direction: column;

        .title {
            width: 100%;
            height: 80px;
            justify-content: center;
            align-items: center;

            span {
                font-size: 28px;
                font-weight: 600;
                color: $span;
            }
        }

        .name {
            width: 100%;
            height: 100px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .text {
                margin-bottom: 10px;

                span {
                    font-size: 22px;
                    font-weight: 500;
                    color: rgba($color: $span, $alpha: .8);
                }
            }

            .input {
                width: min-content;
                align-items: center;
                position: relative;

                svg {
                    position: absolute;
                    left: 10px;
                    width: 22px;
                    height: 22px;
                    color: rgba($color: $span, $alpha: .6)
                }

                input {
                    padding-left: 40px;
                    color: rgba($color: $span, $alpha: .8);
                    width: 250px;
                }
            }

        }

        .select {
            width: 100%;
            height: 70px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            span {
                font-weight: 500;
                margin: 0 10px;
                opacity: .8;
                margin-bottom: 5px;
            }
        }

        .inffoSelect {
            width: 100%;
            height: 40px;
            justify-content: center;
            align-items: center;
            text-align: center;

            span {
                font-size: 10px;
                opacity: .6;
            }
        }

        .inputSimp {
            width: 100%;
            justify-content: center;
            align-items: center;
            position: relative;

            input {
                color: rgba($color: $span, $alpha: .8);
                text-align: center;
                width: 150px;
            }

        }
        .inputSimpfalse{
            display: none;
        }

        .date {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100px;

            .text {
                margin-bottom: 10px;

                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: rgba($color: $span, $alpha: .6);
                }
            }

            .input {
                width: min-content;
                align-items: center;
                position: relative;

                svg {
                    position: absolute;
                    left: 10px;
                    width: 22px;
                    height: 22px;
                    color: rgba($color: $span, $alpha: .6)
                }

                input {
                    padding-left: 40px;
                    color: rgba($color: $span, $alpha: .8);
                    width: 200px;
                }
            }

        }
    }

    .upload {
        width: 100%;
        height: max-content;
        overflow-y: auto;
        flex-direction: column;

        .itenUpload {
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            .dropzone {
                justify-content: center;
                align-items: center;
                border: dotted 1px rgba($color: $span, $alpha: .8);
                border-radius: 20px;
                width: 160px;
                height: 160px;

                svg {
                    width: 60px;
                    height: 60px;
                    color: #888888;
                }
            }

            span {
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 10px;
                color: rgba($color: $span, $alpha: .6);
            }
        }
    }

    input {
        border: solid 1px rgba($color: #FFFFFF, $alpha: .2);
        background-color: rgba($color: #000000, $alpha: 0);
        border-radius: 10px;
        height: 40px;
    }
}