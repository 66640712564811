@import '../../../assets/scss/colorSec';

#Relationship {
    width: 100%;
    height: 100%;
    background-color: $white-light;
    position: relative;

    span {
        color: black;
    }

    .Diary {
        width: 100%;
        height: 100%;
        flex-direction: row;

        .pri {
            width: 50%;
            height: 100%;
            flex-direction: column;
            overflow: hidden;

            .head {
                width: 100%;
                height: 16em;

                .caledar {
                    width: 350px;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    padding: 5px;

                }

                .title {
                    width: calc(100% - 350px);
                    height: 100%;
                    justify-content: center;
                    align-items: center;

                    span {
                        color: rgba($color: $black-darker, $alpha: 0.8);
                        font-weight: 600;
                        font-size: 2em;
                    }
                }
            }

            .graphic {
                justify-content: center;
                align-items: center;
                flex-direction: row;
                width: 100%;
                height: calc(100% - 16em);

                .data {
                    width: 17em;
                    height: 100%;
                    padding: 10px 0;
                    overflow: hidden;

                    .monthlyData {
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        overflow: hidden;

                        .boxValue {
                            margin: 5px 0;
                            width: 220px;
                            height: 90px;
                            border-radius: 15px;
                            background-color: $green-half;
                            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                            flex-direction: column;

                            .bc1 {
                                width: 100%;
                                height: 50%;
                                justify-content: flex-start;
                                padding-left: 20px;
                                align-items: center;

                                svg {
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 10px;
                                    color: #FFFFFF;
                                }


                                .name {
                                    color: rgba($color: $white-darker, $alpha: 0.8);
                                    font-weight: 500;

                                }

                            }

                            .bc2 {
                                width: 100%;
                                height: 50%;
                                justify-content: flex-start;
                                align-items: center;
                                padding-left: 30px;

                                span {
                                    color: rgba($color: $white-darker, $alpha: 0.95);
                                    font-size: 30px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .boxValue:nth-child(1) {
                            background-color: $green-half;
                        }

                        .boxValue:nth-child(2) {
                            background-color: $clear-purple;
                        }

                        .boxValue:nth-child(3) {
                            background-color: $shit-red;
                        }

                        .boxValue:nth-child(4) {
                            background-color: $light-blue;
                        }
                    }
                }

                .dataGp {
                    padding: 25px;
                    width: calc(100% - 17em);
                    height: 100%;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    .circle {
                        width: 19em;
                        height: 19em;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        justify-content: center;
                        align-items: center;

                        .texts {
                            width: auto;
                            height: auto;
                            flex-direction: column;
                            position: absolute;
                            justify-content: center;
                            align-items: center;

                            .label {
                                color: #000000;
                                font-size: 50px;
                                font-weight: 600;

                                .smaller {
                                    color: #000000;
                                    font-size: .45em;
                                    padding-bottom: 20px;
                                    vertical-align: super;
                                }
                            }

                            .use {
                                font-size: 15px;
                                font-weight: 500;
                                z-index: 2;
                            }
                        }
                    }

                    .observation {
                        height: 10%;
                        width: 100%;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;

                        .box {
                            background-color: #4995EB;
                            margin-right: 10px;
                            width: 10px;
                            height: 10px;
                        }

                        span {
                            font-size: 12px;
                        }
                    }
                }
            }

        }

        .sec {
            width: 50%;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px 0;

            .box {
                width: 100%;
                height: 100%;
                justify-content: space-around;
                align-items: center;

                .item {
                    width: 200px;
                    height: 250px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;

                    .bc {
                        width: 100%;
                        height: 70px;
                        background-color: #007380;
                        box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .25);
                        padding: 0 15px;
                        border-radius: 15px;
                        justify-content: space-between;
                        align-items: center;

                        .icon {
                            svg {
                                font-size: 60px;
                                color: rgba($color: $foreground-color, $alpha: .9);
                            }
                        }

                        .text {
                            span {
                                font-size: 30px;
                                font-weight: 500;
                                color: rgba($color: $foreground-color, $alpha: .9);
                            }
                        }
                    }

                    .description {
                        width: 100%;
                        height: calc(100% - 70px);
                        flex-direction: column;
                        align-items: center;

                        .value {
                            width: 100%;
                            height: 60px;
                            padding-top: 10px;
                            justify-content: center;
                            align-items: flex-start;

                            span {
                                font-size: 25px;
                                font-weight: 600;
                                color: rgba($color: $black-dark, $alpha: .9);
                            }

                        }

                        .bar {
                            width: 100%;
                            height: calc(100% - 60px);
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;

                            .barTop,
                            .barBot {
                                width: calc(50% - 25px);
                                height: 20px;
                                justify-content: center;
                                align-items: center;

                                div {
                                    transition: .5s;
                                    width: 0;
                                }
                            }

                            .barTop {
                                padding-left: 5px;
                                justify-content: flex-end;

                                div {
                                    height: 8px;
                                    border-top-left-radius: 10px;
                                    border-bottom-left-radius: 10px;
                                    background-color: #E900FE;
                                    box-shadow: 0 4px 10px 0 rgba($color: #E900FE, $alpha: .6);
                                }
                            }

                            .barBot {
                                padding-right: 5px;
                                justify-content: flex-start;

                                div {
                                    height: 8px;
                                    border-top-right-radius: 10px;
                                    border-bottom-right-radius: 10px;
                                    background-color: #D0A633;
                                    box-shadow: 0 4px 10px 0 rgba($color: #D0A633, $alpha: .6);
                                }
                            }

                            .porc {
                                width: 50px;
                                height: 50px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                box-shadow: 0 0 30px 2px rgba($color: #000000, $alpha: .25);

                                span {
                                    font-size: 16px;
                                    font-weight: 500;
                                    color: rgba($color: $black-dark, $alpha: .9);
                                }
                            }
                        }

                    }
                }

            }

            .box:nth-child(1) {
                .item:nth-child(1)>.bc {
                    background-color: #1C0080;
                    /* Azul escuro */
                }

                .item:nth-child(2)>.bc {
                    background-color: #007380;
                    /* Azul claro */
                }

                .item:nth-child(3)>.bc {
                    background-color: #0F8000;
                    /* Verde */
                }
            }

            .box:nth-child(2) {
                .item:nth-child(1)>.bc {
                    background-color: #E02D2D;
                    /* Azul escuro */
                }

                .item:nth-child(2)>.bc {
                    background-color: #800073;
                    /* Azul claro */
                }
            }
        }
    }

    .toAdd {
        z-index: 10;
        right: 18px;
        top: 10px;
        position: absolute;
        cursor: pointer !important;

        svg {
            font-size: 40px;
        }
    }

    .config-open {
        display: flex;
    }

    .config-closed {
        display: none;
    }

    .ToAddBG {
        position: absolute;
        z-index: 15;
        width: 100%;
        height: 100%;
        background-color: rgb(250, 250, 250);

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Remove as setinhas no Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }

        .inputs {
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;

            form {
                .itens{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    div {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: 15px;
                        padding: 10px;
                        span {
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 5px;
                        }
    
                        input {
                            border: none;
                            border: solid 1px rgba($color: #000000, $alpha: .2);
                            background-color: rgba($color: #000000, $alpha: 0);
                            border-radius: 10px;
                            text-align: center;
                            font-size: 20px;
                            height: 40px;
                            width: 250px;
                            
                        }
                    }
                }

                button {
                    margin-top: 35px;
                    width: 100%;
                    height: 45px;
                    border: none;
                    box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .2);
                    border-radius: 8px;
                    cursor: pointer;
                    background-color: #1C0080;
                    color: white;
                    font-weight: 600;
                    font-size: 18px;
                }
            }
        }

    }
}