@import './color';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", system-ui;
    text-decoration: none;
    outline: none;
    user-select: none;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: $bgColor;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}

div,
header,
section,
footer,
main {
    display: flex;
    position: relative;
}

span{
    color: $span;
}

::-webkit-scrollbar {
    width: 6px;
    background: #292b2f;
    cursor: pointer;
}

::-webkit-scrollbar-thumb {
    background: #36393f;
    border-radius: 3px;
}

span,
img,
tspan {
    user-select: none;
}